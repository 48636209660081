import axios from 'axios';

var axClient = axios.create({});
axClient.defaults.headers.common['Authorization'] = `Bearer ${process.env.VUE_APP_CDS_AUTH_TOKEN}`;
const apiUrl = process.env.VUE_APP_CDS_API_URL;

export default {
    getMakers() {        
        return axClient.get(apiUrl + '/makers')
            .then(res => res.data.makers)
            .catch(e => Promise.reject(e));
    },
    getMaker(label) {
        return axClient.get(apiUrl + '/makers/?label=' + label)
            .then(res => Promise.resolve(res.data.makers[0]))
            .catch(e => Promise.reject(e));
    },
    getModels(makerId) {
        return axClient.get(apiUrl + '/makers/' + makerId + '/models')
            .then(res => res.data.models)
            .catch(e => Promise.reject(e));
    },
    getModel(makerId, modelLabel) {
        return axClient.get(apiUrl + '/makers/' + makerId + '/models/?label=' + modelLabel)
            .then(res => Promise.resolve(res.data.models[0]))
            .catch(e => Promise.reject(e));
    },
    getBodyDesigns(modelId) {
        return axClient.get(apiUrl + '/models/' + modelId + '/body_designs')
            .then(res => res.data.body_designs)
            .catch(e => Promise.reject(e));
    },
    getBodyDesign(modelId, bodyDesignLabel) {
        return axClient.get(apiUrl + '/models/' + modelId + '/body_designs/?label=' + bodyDesignLabel)
            .then(res => res.data.body_designs[0])
            .catch(e => Promise.reject(e));
    },
    getTrimLevels(bodyDesignId) {
        return axClient.get(apiUrl + '/body_designs/' + bodyDesignId + '/trim_levels')
            .then(res => res.data.trim_levels)
            .catch(e => Promise.reject(e));
    },
    getTrimLevel(bodyDesignId, trimLevelLabel) {
        return axClient.get(apiUrl + '/body_designs/' + bodyDesignId + '/trim_levels/?label=' + encodeURIComponent(trimLevelLabel))
            .then(res => res.data.trim_levels[0])
            .catch(e => Promise.reject(e));
    },
    getCompairsion(engineId) {
        return axClient.get(apiUrl + '/engines/' + engineId + '/equipments_comparisons')
            .then(res => res.data)
            .catch(e => Promise.reject(e));
    },
    getEngines(trimLevelId) {
        return axClient.get(apiUrl + '/trim_levels/' + trimLevelId + '/engines')
            .then(res => res.data.engines)
            .catch(e => Promise.reject(e));
    },
    getEngine(trimLevelId, engineLabel) {
        return axClient.get(apiUrl + '/trim_levels/'+trimLevelId+'/engines/?label='+encodeURIComponent(engineLabel))
            .then(res => res.data.engines[0])
            .catch(e => Promise.reject(e));
    },
    getExtras(engineId) {
        return axClient.get(apiUrl + '/engines/'+engineId+'/extras/?include=rules')
            .then(res => res.data.extras)        
            .catch(e => Promise.reject(e));
    },
    getCarConfiguration(configurationId) {
        return axClient.get(apiUrl + '/car_configurations/?external_id='+configurationId)
            .then(res => res.data.car_configurations)
            .catch(e => Promise.reject(e))
    },
    saveCarConfiguration(carConfiguration) {
        return axClient.post(apiUrl+'/car_configurations/', carConfiguration)
            .then(res => res.data.car_configuration)
            .catch(e => Promise.reject(e));            
    },
    updateCarConfiguration(carConfiguration) {
        return axClient.put(apiUrl+'/car_configurations/'+carConfiguration.car_configuration.id, carConfiguration)
            .then(res => res.data.car_configuration)
            .catch(e => Promise.reject(e));            
    },
    submitOfferRequest(configurationId) {
        return axClient.put(apiUrl+'/offer_requests/'+configurationId).then(res => res)
        .catch(e => Promise.reject(e));
    },
    getOfferPdf(configurationId) {
        return axClient.get(apiUrl+'/pdf/offer/'+configurationId).then(res => res.data)
        .catch(e => Promise.reject(e));
    },
    getStandardEquipments(engineId) {
        return axClient.get(apiUrl+'/engines/'+engineId+'/standard_equipments/')
            .then(res => res.data.standard_equipments)
            .catch(e => Promise.reject(e));
    },
    dataPush(request) {
        return axClient.post(apiUrl+'/car_configurations/ef_data_push', request).then(res => res.data)
        .catch(e => Promise.reject(e));
    },
    getConditions() {
        return axClient.get(apiUrl+'/conditions').then(res => res.data)
            .catch(e => Promise.reject(e));
    }
}