<template>
  <editor-card title="Highlights">
    <v-text-field label="Highlight 1" v-model="vehicle.id_208"></v-text-field>
    <v-text-field label="Highlight 2" v-model="vehicle.id_209"></v-text-field>
    <v-text-field label="Highlight 3" v-model="vehicle.id_210"></v-text-field>
  </editor-card>
  
</template>

<script>
import EditorCard from "./EditorCard.vue";

export default {
  name: "highlights-editor",
  props: ["vehicle"],
  components: { EditorCard },
};
</script>