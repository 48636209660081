<template>
  <editor-card title="Leasingdetails 1">
    <v-row>
      <v-col>
        <v-text-field
        label="(TBD) Vertragsart"
        type="string"
        v-model="vehicle.id_999983"
        disabled="disabled"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <LeasingTargetGroupSelect v-model="vehicle.id_297" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="UPE Brutto"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_287"
          placeholder="z.B.38550,00"
        ></v-text-field>
      </v-col>
      <v-col>
      <v-text-field
        label="LF"
        type="number"
        min="0.0"
        suffix="%"
        v-model.number="vehicle.id_615"
      ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Gesamtbetrag brutto"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_306"
          placeholder="Kalkulation (wenn möglich siehe Tickets)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Leasingsonderkonditionen (Prämien)"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_346"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="BAFA Umweltprämie"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_390"
          placeholder="z.B. 0"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="(TBD) Sonderzahlung"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_999988"
          placeholder="z.B. 0"
        ></v-text-field>
      </v-col>
    </v-row>


<!--

    <v-text-field
      label="Mehrwertsteuersatz"
      type="number"
      min="0.0"
      suffix="%"
      v-model.number="vehicle.id_29"
    >
    </v-text-field>





    
    <v-text-field
      label="Laufzeit"
      type="number"
      min="0"
      suffix="Monate"
      v-model.number="vehicle.id_294"
    ></v-text-field>
    <v-text-field
      label="Gesamtleasinglaufleistung"
      type="number"
      min="0"
      suffix="km"
      v-model.number="vehicle.id_298"
    ></v-text-field>
    <v-text-field
      label="Monatl. Leasingrate (Brutto) inkl. MwSt."
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_293"
    ></v-text-field>
    <v-text-field
      label="Mehrkilometer"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_300"
    ></v-text-field>
    <v-text-field
      label="Minderkilometer"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_301"
    ></v-text-field>
    <v-checkbox label="verhandelbar (TODO: Welches Feld?)"></v-checkbox>
    <v-text-field
      label="Leasinggesamtbetrag"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_306"
    ></v-text-field>
    <v-text-field
      label="Nettokreditbetrag"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_307"
    ></v-text-field>
    <v-text-field
      label="Restwert nach Ende (inkl. MwSt.)"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_299"
    ></v-text-field>
    
    
    <v-text-field
      label="Leasingsonderkonditionen(Prämien)"
      v-model="vehicle.id_346"
    ></v-text-field>
    <span
      >Trenner ist | 1=Inzahlungnahmebonus 2=Umweltbonus 3=Umweltprämie
      4=Eroberungsprämie 5=Behindertenrabatt 6=Behördenrabatt 7=BW eGutschein
      8=NRW Bonus 9=Berlin Bonus</span
    >
    <v-text-field
      label="Haftpflicht / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_379"
    ></v-text-field>
    <v-text-field
      label="Haftpflicht SB"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_380"
    ></v-text-field>
    <v-text-field
      label="Vollkasko / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_381"
    ></v-text-field>
    <v-text-field
      label="Vollkasko SB"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_382"
    ></v-text-field>
    
    <v-text-field
      label="KFZ-Steuer / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_384"
    ></v-text-field>
    
    <v-text-field
      label="HU-AU / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_386"
    ></v-text-field>
    <v-text-field
      label="Rückgabeversicherung / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_387"
    ></v-text-field>
    <v-text-field
      label="Zulassungskosten einmalig"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_388"
    ></v-text-field>

-->
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";
import LeasingTargetGroupSelect from "../controls/LeasingTargetGroupSelect.vue";


export default {
  name: "LeasingDetails1",
  props: ["vehicle"],
  components: { EditorCard,LeasingTargetGroupSelect },
};
</script>
