<template>
  <div>
    <v-form>
      <v-select
        label="Schadstoff"
        :items="emissionClasses"
        item-text="name"
        item-value="value"
        v-model.number="selectedEmissionClass"
        v-on:input="emitChangedValue"
      ></v-select>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "emission-class-select",
  props: ["value"],
  data() {
    return {
      emissionClasses: [
        {
          name: "Euro1",
          value: 1,
        },
        {
          name: "Euro2",
          value: 2,
        },
        {
          name: "Euro3",
          value: 3,
        },
        {
          name: "Euro4",
          value: 4,
        },
        {
          name: "Euro5",
          value: 5,
        },
        {
          name: "Euro6",
          value: 6,
        },
      ],
      selectedEmissionClass: null,
    };
  },
  methods: {
    emitChangedValue() {
      if (this.selectedEmissionClass) {
        this.$emit("input", this.selectedEmissionClass);
      }
    },
    parseInputValue(val) {
      if (val) {
        this.selectedEmissionClass = parseInt(val);
      }
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
