<template>
  <v-form>
    <v-select
      label="Kraftstoffart"
      :items="fuels"
      item-text="name"
      item-value="value"
      v-model="selectedFuel"
      v-on:input="emitChangedValue"
    ></v-select>
  </v-form>
</template>

<script>
export default {
  name: "fuel-select",
  props: ["value"],
  data() {
    return {
      fuels: [
        {
          name: "Benzin",
          value: 1,
        },
        {
          name: "Diesel",
          value: 2,
        },
        {
          name: "Autogas",
          value: 3,
        },
        {
          name: "Erdgas",
          value: 4,
        },
        {
          name: "Elektro",
          value: 6,
        },
        {
          name: "Hybrid",
          value: 7,
        },
        {
          name: "Wasserstoff",
          value: 8,
        },
        {
          name: "Ethanol",
          value: 9,
        },
        {
          name: "Hybrid-Diesel",
          value: 10,
        },
        {
          name: "Bi-Fuel",
          value: 11,
        },
        {
          name: "Andere",
          value: 0,
        },
      ],
      selectedFuel: null,
    };
  },
  methods: {
    parseInputValue(val) {
      if (val) {
        this.selectedFuel = parseInt(val);
      }
    },
    emitChangedValue() {
      if (this.selectedFuel || this.selectedFuel == 0) {
        this.$emit("input", this.selectedFuel);
      }
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
