<template>
  <editor-card title="Fahrzeugbeschreibung">

      <v-row>
        <v-col>
          <v-text-field label="Beschreibung komplett" class="required" v-model="vehicle.id_611" required placeholder="z.B. Ford Kuga 2,5 L Duratec PHEV + BAFA-Garantie +" clearable></v-text-field>
          <v-text-field label="Beschreibung lang" class="required" v-model="vehicle.id_614"  required placeholder="z.B. Ford Kuga 2,5 L Duratec PHEV" clearable></v-text-field>
          <v-text-field label="Beschreibung kurz" class="required" v-model="vehicle.id_617" required placeholder="z.B. Ford Kuga" clearable></v-text-field>
          <v-text-field label="Zusatz Info" class="required" v-model="vehicle.id_616" required placeholder="z.B. + BAFA-Garantie +" clearable></v-text-field>
        </v-col>
      </v-row>
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";


export default {
  name: "make-model-editor",
  props: ["vehicle"],
  components: { EditorCard },
};
</script>


