<template>
    <v-select
      label="Fahrzeugkategorie"
      :items="categories"
      item-text="name"
      item-value="name"
      v-model="selectedCategory"
      v-on:input="emitChangedValue"
      required
    ></v-select>
</template>

<script>
export default {
  name: "cartype-select",
  props: ["value"],
  data() {
    return {
      categories: [
        {
          name: "Cabrio/Roadster",
        },
        {
          name: "Kombi",
        },
        {
          name: "Limousine",
        },
        {
          name: "SUV/Geländewagen/Pickup",
        },
        {
          name: "Kleinwagen",
        },
        {
          name: "Sportwagen/Coupé",
        },
        {
          name: "Van/Kleinbus",
        },
        {
          name: "Andere",
        }
      ],
      selectedCategory: null,
    };
  },
  methods: {
    parseInputValue(val) {
      if (val) {
        this.selectedCategory = val;
      }
    },
    emitChangedValue() {
      if (this.selectedCategory || this.selectedCategory == 0) {
        this.$emit("input", this.selectedCategory);
      }
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
