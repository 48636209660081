<template>
  <editor-card title="Gültigkeit">

    <v-row class="justify-space-around pa-3">
      <v-div>
        <h3 class="pb-4 text-center">Aktiv von</h3>
    <v-date-picker
      label="Angebot ist gültig von (tt.mm.jjjj)"
      v-model="vehicle.id_604"
      color="green lighten-1"
      locale="de">
      </v-date-picker>
    </v-div>
      <v-div>
        <h3 class="pb-4 text-center">Aktiv bis</h3>
        <v-date-picker
      label="Angebot ist gültig bis (tt.mm.jjjj)"
      v-model="vehicle.id_605"
      locale="de">
      </v-date-picker>
    </v-div>
  </v-row>

    <!--<v-text-field
      label="Angebot ist gültig von (tt.mm.jjjj)"
      type="string"
      v-model="vehicle.id_604"
    ></v-text-field>
    <v-text-field
      label="Angebot ist gültig bis (tt.mm.jjjj)"
      type="string"
      v-model="vehicle.id_605"
    ></v-text-field>-->

  </editor-card>
  
</template>

<script>
import EditorCard from "./EditorCard.vue";

export default {
  name: "description-editor",
  props: ["vehicle"],
  components: { EditorCard },
};
</script>
