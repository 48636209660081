<template>
  <editor-card title="Umwelt">
    <emission-class-select v-model="vehicle.id_61" />
    <PolutionClassSelect v-model="vehicle.id_308" />
    <energy-efficiency-label-select v-model="vehicle.id_169" />    
    <pollution-badge-select v-model="vehicle.id_43" />
    <!--<v-text-field
      label="CO2-Emissionen kombiniert"
      v-model.number="vehicle.id_99"
      suffix="g/km"
    />-->
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";
import EmissionClassSelect from "../controls/EmissionClassSelect.vue";
import PollutionBadgeSelect from "../controls/PollutionBadgeSelect.vue";
import EnergyEfficiencyLabelSelect from "../controls/EnergyEfficiencyLabelSelect.vue";
import PolutionClassSelect from '../controls/PolutionClassSelect.vue';
export default {
  name: "environment-editor",
  props: ["vehicle"],
  components: {
    EditorCard,
    EmissionClassSelect,
    PollutionBadgeSelect,
    EnergyEfficiencyLabelSelect,
    PolutionClassSelect
  },
};
</script>
