<template>
  <editor-card title="Serienausstattung">
    <v-textarea
      label="Serienausstattung"
      v-model="vehicle.id_622"
      counter="5000"
      maxlength="5000"
      auto-grow
      placeholder="Winterpaket, Winterräder, Navigation,…"
    ></v-textarea>
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";

export default {
  name: "equipment-editor",
  props: ["vehicle"],
  components: { EditorCard },
};
</script>
