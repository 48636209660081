<template>
  <editor-card title="Kraftstoffverbrauch">
      <v-row>
        <v-col>
        <fuel-select v-model="vehicle.id_109" />
        <!-- TODO: Eingabe von Komma (und Punkt) ermöglichen -->
        <!-- TODO: (bei Erdgas in kg/100km, wird ignoriert bei Elektro)  -->
      </v-col>
      <v-col>
        <v-checkbox
          label="Plug-In"
          v-model.number="vehicle.id_206"
          :false-value="0"
          :true-value="1"
        />
      </v-col>
    </v-row>
    <v-text-field
      label="Kombiniert"
      suffix="l/100km"
      type="number"
      v-model.number="vehicle.id_98"
      placeholder="z.B. 5,6"
    ></v-text-field>
    <!-- TODO: Eingabe von Komma (und Punkt) ermöglichen -->
    <!-- TODO: (bei Erdgas in kg/100km, wird ignoriert bei Elektro)  -->
    <v-text-field
      label="Innerorts"
      suffix="l/100km"
      type="number"
      v-model.number="vehicle.id_96"
      placeholder="z.B. 6,9"
    ></v-text-field>
    <!-- TODO: Eingabe von Komma (und Punkt) ermöglichen -->
    <!-- TODO: (bei Erdgas in kg/100km, bei Elektro in kWh/100km) -->
    <v-text-field
      label="Außerorts"
      suffix="l/100km"
      type="number"
      v-model.number="vehicle.id_97"
      placeholder="z.B. 4,8"
    ></v-text-field>
    <v-text-field
      label="Stromverbrauch komb."
      suffix="kWh/100km"
      type="number"
      v-model.number="vehicle.id_207"
      placeholder="z.B. 20,2"
    ></v-text-field>
    <v-text-field
      label="CO2-Emissionen kombiniert"
      v-model.number="vehicle.id_99"
      suffix="g/km"
      placeholder="z.B. 160"
    />
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";
import FuelSelect from "../controls/FuelSelect.vue";

export default {
  name: "fuel-consumption-editor",
  props: ["vehicle"],
  components: { FuelSelect, EditorCard },
};
</script>
