  <template>
  <editor-card title="Services kostenpflichtig">
    <v-row>
      <v-col>
        <v-text-field
          label="Überführung"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_107"
          placeholder="z.B. 990"
        ></v-text-field>
        <v-text-field
          label="Wartung / Monat"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_376"
          placeholder="z.B. 10"
        ></v-text-field>
        <v-text-field
          label="Verschleiß / Monat"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_377"
          placeholder="z.B. 8"
        ></v-text-field>
        <v-text-field
          label="Wartung & Verschleiß / Monat"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_378"
          placeholder="z.B. 18"
        ></v-text-field>
        <v-text-field
          label="KFZ-Lieferung einmalig"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_383"
          placeholder="z.B. 299"
        ></v-text-field>
        <v-text-field
          label="8-fach Bereifung / Monat"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_385"
          placeholder="z.B. 50"
        ></v-text-field>
        <v-row >
          <v-col cols="6">
            <v-text-field
              label="Haftpflicht / Monat"
              type="number"
              min="0"
              suffix="€"
              v-model.number="vehicle.id_379"
              placeholder="z.B. 20"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Haftpflicht SB"
              type="number"
              min="0"
              suffix="€"
              v-model.number="vehicle.id_380"
              placeholder="z.B. 500"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="6">
          <v-text-field
            label="Vollkasko / Monat"
            type="number"
            min="0"
            suffix="€"
            v-model.number="vehicle.id_381"
            placeholder="z.B. 50"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Vollkasko SB"
            type="number"
            min="0"
            suffix="€"
            v-model.number="vehicle.id_382"
            placeholder="z.B. 1000"
          ></v-text-field>
        </v-col>
      </v-row>
        <v-text-field
          label="KFZ-Steuer / Monat"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_384"
          placeholder="z.B. 20"
        ></v-text-field>
        <v-text-field
          label="HU-AU / Monat"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_386"
          placeholder="z.B. 5"
        ></v-text-field>
      </v-col>
    </v-row>

<!--
<v-text-field
      label="KFZ-Lieferung einmalig"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_383"
    ></v-text-field>
    <v-text-field
      label="Angebot eingestellt am (tt.mm.jjjj)"
      type="string"
      v-model="vehicle.id_603"
    ></v-text-field>
    <AdStatusSelect v-model="vehicle.id_606" />
    <v-textarea
      label="Beschreibung komplett"
      v-model="vehicle.id_611"
      auto-grow
    ></v-textarea>
    <v-textarea
      label="Beschreibung lang"
      v-model="vehicle.id_614"
      auto-grow
    ></v-textarea>
    <v-textarea
      label="Beschreibung kurz"
      v-model="vehicle.id_617"
      auto-grow
    ></v-textarea>
    <v-textarea
      label="IntroBubble-Aktionsstatus"
      v-model="vehicle.id_609"
      auto-grow
    ></v-textarea>
     <v-textarea
      label="Beschreibung-Bubble"
      v-model="vehicle.id_610"
      counter="80"
      maxlength="80"
      auto-grow
    ></v-textarea>
    <CarStatusSelect v-model="vehicle.id_618" />
    <v-checkbox
      label="Konfigurierbar?"
      v-model.number="vehicle.id_619"
      :false-value="0"
      :true-value="1"
    />
    <v-textarea
      label="Spezialausstattung (trenner ,)"
      v-model="vehicle.id_621"
      counter="600"
      maxlength="600"
      auto-grow
    ></v-textarea>
    

    <v-textarea
      label="Ausstattungshighlights-Karte (trenner ,)"
      v-model="vehicle.id_624"
      counter="40"
      maxlength="40"
      auto-grow
    ></v-textarea>
  -->
  </editor-card>
</template>

<script>
//import AdStatusSelect from "../controls/AdStatusSelect.vue";
//import CarStatusSelect from '../controls/CarStatusSelect.vue'
import EditorCard from "./EditorCard.vue";

export default {
  name: "service-extra-editor",
  props: ["vehicle"],
  data() {
    return {};
  },
  methods: {},
  components: { EditorCard, },
};
</script>
