<template>
  <editor-card title="Bemerkung">
    <!-- TODO: Creole-Parser anschließen/schreiben -->
    <v-textarea
      v-model="vehicle.id_25"
      counter="5000"
      maxlength="5000"
      auto-grow
      label="Bemerkung"
      placeholder="Freitext"
    ></v-textarea>
  </editor-card>
  
</template>

<script>
import EditorCard from "./EditorCard.vue";

export default {
  name: "description-editor",
  props: ["vehicle"],
  components: { EditorCard },
};
</script>
