<template>
  <editor-card title="Leasingdaten">
    <v-text-field
      label="Leasinggesellschaft"
      v-model="vehicle.id_302"
      placeholder="z.B. Leasing Musterbank AG, Musterstrasse 1, 12345 Musterhausen">
    </v-text-field>
    <v-text-field
      label="Leasingbedingungen"
      v-model="vehicle.id_305"
      placeholder="z.B. Angebot ist nur gültig..."
    ></v-text-field>
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";

export default {
  name: "leasing-editor",
  props: ["vehicle"],
  components: { EditorCard },
};
</script>
