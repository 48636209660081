<template>
  <v-card>
    <v-toolbar class="elevation-0" color="secondary">
      <v-toolbar-title class="card-title orange--text">
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <slot></slot>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "editor-card",
  props: ["title"],
};
</script>
