<template>
  <editor-card title="Services enthalten">
    <v-row>
      <v-col>
        <v-checkbox
          label="Überführung"
          v-model.number="vehicle.id_347"
          :false-value="0"
          :true-value="1"
        />
        <v-checkbox
          label="Zulassung"
          v-model.number="vehicle.id_348"
          :false-value="0"
          :true-value="1"
        />
        <v-checkbox
          label="KFZ-Lieferung"
          v-model.number="vehicle.id_349"
          :false-value="0"
          :true-value="1"
        />
        <v-checkbox
          label="Wartung"
          v-model.number="vehicle.id_370"
          :false-value="0"
          :true-value="1"
        />
           <v-checkbox
          label="Verschleiß"
          v-model.number="vehicle.id_371"
          :false-value="0"
          :true-value="1"
        />
      </v-col>
    </v-row>
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";

export default {
  name: "service-included",
  props: ["vehicle"],
  components: { EditorCard },
};
</script>
