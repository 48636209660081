<template>
  <editor-card title="Zustand">
    <v-row>
      <v-col cols="6">
        <v-checkbox
          label="Neuwagen"
          v-model.number="vehicle.id_21"
          :false-value="0"
          :true-value="1"
        />
        </v-col>
        <v-col cols="6">
            <v-checkbox
              label="Konfigurierbar"
              v-model.number="vehicle.id_619"
              :false-value="0"
              :true-value="1"
            />
          </v-col>
        </v-row>
      <CarTypeSelect v-model="vehicle.id_2"></CarTypeSelect>
      <v-row>
        <v-col>
          <v-text-field
            label="Kilometerstand*"
            type="number"
            v-model.number="vehicle.id_9"
            suffix="KM"
            required
            placeholder="z.B. 10"
            clearable
          />
        </v-col>
      </v-row>
        <first-registration-select v-model="vehicle.id_8" />
        <v-text-field label="Fahrgestell-Nr. | VIN" v-model="vehicle.id_14" placeholder="z.B. VN374837847384" clearable></v-text-field>
        <v-text-field label="Interne ID" class="required" v-model="vehicle.id_1"  required placeholder="z.B. TAG-1234"  clearable></v-text-field>
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";
import FirstRegistrationSelect from "../controls/FirstRegistrationSelect.vue";
import CarTypeSelect from '../controls/CarTypeSelect.vue';


export default {
  name: "condition-editor",
  props: ["vehicle"],
  components: { FirstRegistrationSelect, EditorCard, CarTypeSelect },
};
</script>

<style>
</style>
