import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URI;

export default {
    getMakers() {
        return axios.get(apiUrl + '/ImageService/Makers').then(res => res.data).catch(err => Promise.reject(err));
    },
    getModels(maker) {
        return axios.get(apiUrl + '/ImageService/ModelsForMaker/'+maker).then(res => res.data).catch(err => Promise.reject(err));
    },
    getTrimLevels(maker, model) {
        return axios.get(apiUrl + '/ImageService/TrimLevels/'+maker+'/'+model).then(res => res.data).catch(err => Promise.reject(err));
    },
    getByMakerModelTrimLevel(maker, model, trimLevel) {
        return axios.get(apiUrl + '/ImageService/FindByMakerAndModelAndTrimLevel/'+maker+'/'+model+'/'+trimLevel).then(res => res.data).catch(err => Promise.reject(err));
    },
    getByHsnTsn(hsn, tsn) {
        return axios.get(apiUrl + '/ImageService/FindByHsnTsn/'+hsn+'/'+tsn).then(res => res.data).catch(err => Promise.reject(err));
    }
}