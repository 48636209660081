<template>
  <editor-card title="Lieferzeit">
    <!--<v-btn-toggle>
      <v-btn> Bestellfahrzeug </v-btn>
      <v-btn> Lagerfahrzeug </v-btn>
    </v-btn-toggle>-->
    <v-text-field label="Lieferzeit" class="required" v-model.number="vehicle.id_106" required placeholder="z.B. 90" clearable suffix="Tage"
></v-text-field>


    <!--<DeliveryTimeSelect v-model.number="vehicle.id_106"></DeliveryTimeSelect>-->
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";
//import DeliveryTimeSelect from '../controls/DeliveryTimeSelect.vue'

export default {
  name: "availability-editor",
  props: ["vehicle"],
  components: { EditorCard },
};
</script>
