<template>
  <v-form>
    <v-select
      label="Innenfarbe"
      :items="colors"
      item-text="name"
      item-value="value"
      v-model="selectedColor"
      v-on:input="emitChangedValue"
    ></v-select>
  </v-form>
</template>

<script>
export default {
  name: "insidecolor-select",
  props: ["value"],
  data() {
    return {
      colors: [
        {
          name: 'Schwarz',
          value: 1,
        },
        {
          name: 'Grau',
          value: 2,
        },
        {
          name: 'Beige',
          value: 3,
        },
        {
          name: 'Braun',
          value: 4,
        },
        {
          name: 'Weiß',
          value: 6,
        },
        {
          name: 'Andere',
          value: 5,
        },
      ],
      selectedColor: null,
    };
  },
  methods: {
    parseInputValue(val) {
      if (val) {
        this.selectedColor = parseInt(val);
      }
    },
    emitChangedValue() {
      if (this.selectedColor || this.selectedColor == 0) {
        this.$emit("input", this.selectedColor);
      }
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
